import isDerivedOperations from '@/media/utility/isDerivedImage';
import useTransformUrlToImage from '@/media/hooks/useTransformUrlToImage';
import useImageSourceId from '@/media/hooks/useImageSourceId';
import { useUploadFiles } from '@/storychief/hooks';

export default function useProcessImage({
  uploadFolder = null,
  saveToDatabase = false,
  isPrivate = false,
  uploadOnSave = false,
  onCompleted = () => {},
  onTransformed = () => {},
}) {
  const { transformUrlToImage } = useTransformUrlToImage();
  const { getSourceId } = useImageSourceId();
  const [upload] = useUploadFiles({
    uploadFolder,
    saveToDatabase,
    isPrivate,
    onCompleted: (images) => {
      onCompleted(images[0]);
    },
  });

  async function processImage({ editedImage, originalImageUrl, editedImageTransformData }) {
    try {
      const attachable = await transformUrlToImage(originalImageUrl);
      onTransformed();
      // eslint-disable-next-line no-param-reassign
      editedImage.data = {
        editedImageTransformData,
        ...(attachable && {
          sourceId: await getSourceId(attachable, editedImageTransformData),
          derived: isDerivedOperations(editedImageTransformData),
        }),
      };

      if (uploadOnSave) {
        upload([editedImage]);
      } else {
        onCompleted(editedImage);
      }
    } catch (error) {
      throw error;
    }
  }

  return processImage;
}
