import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useReactiveVar } from '@apollo/client';
import FieldDragDropNotice from '@/storychief/components/custom-fields/FieldDragDropNotice';
import { isUserDraggingFileVar } from '@/graphql/cache';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDrop: PropTypes.func.isRequired,
};
const defaultProps = {
  className: null,
};

function FileDragDrop({ className, children, allowedFileTypes, onDrop }) {
  const [isUserDraggingFileOnField, setIsUserDraggingFileOnField] = useState(false);
  const isUserDraggingFile = useReactiveVar(isUserDraggingFileVar);

  function handleOnDragEnter() {
    setIsUserDraggingFileOnField(true);
  }

  function handleOnDragLeave() {
    setIsUserDraggingFileOnField(false);
  }

  function handleOnDragOver(e) {
    e.preventDefault();
  }

  function handleOnDrop(e) {
    e.preventDefault();
    setIsUserDraggingFileOnField(false);

    onDrop(e.dataTransfer.files);
  }

  return (
    <div
      onDragOver={handleOnDragOver}
      onDragEnter={handleOnDragEnter}
      onDragLeave={handleOnDragLeave}
      onDrop={handleOnDrop}
      className={classNames(className, {
        'user-dragging-file': isUserDraggingFile && !isUserDraggingFileOnField,
        'user-dropping-file-on-field': isUserDraggingFileOnField,
      })}
    >
      <FieldDragDropNotice allowedFileTypes={allowedFileTypes} />

      {children}
    </div>
  );
}

FileDragDrop.propTypes = propTypes;
FileDragDrop.defaultProps = defaultProps;

export default FileDragDrop;
