import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { Icon } from '@iconify-icon/react';
import { useDidUpdateEffect, usePrevious, useUploadFiles } from '@/storychief/hooks';
import MediaPicker from '@/media/components/MediaPicker';
import { fileTypesImages } from '@/media/constants/Constants';
import MediaItem from '@/media/components/MediaItem';
import useProcessImage from '@/media/hooks/useProcessImage';
import FileDragDrop from '@/storychief/components/custom-fields/FileDragDrop';

const propTypes = {
  display: PropTypes.oneOf(['field', 'fieldPreview', 'fieldValue', 'datatable']),
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  allowedFileTypes: PropTypes.arrayOf(
    PropTypes.shape({
      mime: PropTypes.string.isRequired,
      extension: PropTypes.string.isRequired,
    }),
  ),
  fieldDefinition: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    important: PropTypes.bool,
    crop: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    alt: PropTypes.string,
    description: PropTypes.string,
    help: PropTypes.string,
    default: PropTypes.string,
    uploadFolder: PropTypes.string,
    saveToDatabase: PropTypes.bool,
    isPrivate: PropTypes.bool,
    actions: PropTypes.shape({
      showDownload: PropTypes.bool,
      showView: PropTypes.bool,
      size: PropTypes.string,
    }),
  }).isRequired,
  fieldValue: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  onUpdateCallback: PropTypes.func.isRequired,
  onAltUpdateCallback: PropTypes.func,
  onUploadingCallback: PropTypes.func,
  bsSize: PropTypes.string,
};
const defaultProps = {
  disabled: false,
  visible: true,
  display: 'field',
  onAltUpdateCallback: null,
  onUploadingCallback: () => {},
  allowedFileTypes: fileTypesImages,
  bsSize: undefined,
};

function ImageFieldType({
  display,
  disabled,
  visible,
  allowedFileTypes,
  fieldDefinition,
  fieldValue,
  onUpdateCallback,
  onUploadingCallback,
  onAltUpdateCallback,
  bsSize,
}) {
  // States
  const [image, setImage] = useState({
    key: fieldDefinition.name,
    url: fieldValue.value,
  });
  const [droppedItems, setDroppedItems] = useState(null);

  // Previous
  const prevFieldValue = usePrevious(fieldValue);

  const [upload] = useUploadFiles({
    uploadFolder: fieldDefinition.uploadFolder,
    saveToDatabase: fieldDefinition.saveToDatabase,
    isPrivate: fieldDefinition.isPrivate,
    sourceUrl: image.url,
    onUploading: (images) => {
      setImage((prevImage) => ({
        ...prevImage,
        ...images[0],
      }));
      onUploadingCallback();
    },
    onCompleted: (images) => {
      // eslint-disable-next-line no-use-before-define
      setImage((prevImage) => ({
        ...prevImage,
        url: images[0].url,
      }));
      onUpdateCallback(getReturnObject(images[0].url));
    },
  });

  // Variables
  const classNameWrapper = classNames('container-upload-image row-table ', {
    empty: !image.url,
    disabled,
  });

  // Hooks
  const processImage = useProcessImage({
    saveToDatabase: fieldDefinition.saveToDatabase,
    onCompleted: (imageEdited) => {
      handleOnLoadCompleted([imageEdited]);
    },
  });

  // Effects
  useDidUpdateEffect(() => {
    // check if prop fieldValue has changed to update state
    if (prevFieldValue.value !== fieldValue.value) {
      setImage({ name: fieldDefinition.name, url: fieldValue.value });
    }
  }, [fieldValue.value]);

  const prevVisible = usePrevious(visible);
  useDidUpdateEffect(() => {
    if (visible !== prevVisible) {
      handleChange('');
    }
  }, [visible]);

  // Functions
  function getReturnObject(url) {
    return {
      key: fieldDefinition.name,
      value: url,
    };
  }

  function handleChange(url) {
    setImage((prevImage) => ({
      ...prevImage,
      url,
    }));
    onUpdateCallback(getReturnObject(url));
  }

  function handleOnLoadCompleted(images) {
    setDroppedItems(null);

    if (images[0].id) {
      handleChange(images[0].url);
    } else {
      upload(images);
      setImage((prevImage) => ({
        ...prevImage,
        ...images[0],
      }));
    }
  }

  function onImageRemove() {
    handleChange('');
  }

  function handleOnDrop(files) {
    setDroppedItems(files);
  }

  if (!visible) {
    return <div />;
  }

  switch (display) {
    case 'datatable':
      return (
        <MediaItem
          object={{ mime_type: 'image/*', ...image }}
          backgroundImage
          maxWidth={fieldDefinition.maxWidth}
          maxHeight={fieldDefinition.maxHeight}
          className="image--thumb"
          saveToDatabase={fieldDefinition.saveToDatabase}
        />
      );
    case 'fieldValue':
      return (
        <div className="d-inline-block position-relative">
          {image.url ? (
            <MediaItem
              object={{ mime_type: 'image/*', ...image }}
              maxWidth={fieldDefinition.maxWidth}
              maxHeight={fieldDefinition.maxHeight}
              saveToDatabase={fieldDefinition.saveToDatabase}
              showDownloadAction={!!fieldDefinition?.actions?.showDownload}
              showViewAction={!!fieldDefinition?.actions?.showView}
              actionsSize={fieldDefinition?.actions?.size}
            />
          ) : (
            '-'
          )}
        </div>
      );
    case 'fieldPreview':
      return <span className="text-muted">No preview available</span>;
    default:
      return (
        <FormGroup controlId={fieldDefinition.name} className="custom-field" bsSize={bsSize}>
          {!!fieldDefinition.label && (
            <ControlLabel bsClass="control-label">
              {fieldDefinition.label}
              {fieldDefinition.important && !fieldDefinition.description && (
                <em className="icon-attention-alt text-warning" />
              )}
              {fieldDefinition.important && fieldDefinition.description && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id={`help-${fieldDefinition.name}`}>
                      Important! {fieldDefinition.description}
                    </Tooltip>
                  }
                >
                  <span className="icon-attention-alt text-warning" />
                </OverlayTrigger>
              )}
              {!fieldDefinition.important && fieldDefinition.description && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id={`help-${fieldDefinition.name}`}>
                      {fieldDefinition.description}
                    </Tooltip>
                  }
                >
                  <Icon icon="fa:question-circle" inline className="text-muted ml-1" width="14" />
                </OverlayTrigger>
              )}
            </ControlLabel>
          )}
          <FileDragDrop
            allowedFileTypes={allowedFileTypes}
            onDrop={handleOnDrop}
            className={classNameWrapper}
          >
            <div className="container-upload-image__main">
              {!image.url ? (
                <div>
                  <div className="children space-top-5 space-5 gutter-3">
                    <MediaPicker
                      className="btn-outline"
                      onLoadCompleted={handleOnLoadCompleted}
                      disabled={disabled}
                      crop={fieldDefinition.crop}
                      minHeight={fieldDefinition.height}
                      minWidth={fieldDefinition.width}
                      allowedFileTypes={allowedFileTypes}
                      multiSelect={false}
                      saveToDatabase={fieldDefinition.saveToDatabase}
                      droppedItems={droppedItems}
                    />
                    <div className="text-muted space-top-2 xsmall initial-line-height">
                      {allowedFileTypes.map((f) => f.extension).join(', ')}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <MediaItem
                    object={{ mime_type: 'image/*', ...image, alt: fieldDefinition.alt }}
                    maxWidth={fieldDefinition.maxWidth}
                    maxHeight={fieldDefinition.maxHeight}
                    actionsDisabled={disabled}
                    backgroundImage={false}
                    onEdited={(editedImage, editedImageTransformData) => {
                      setImage({ ...image, uploading: true, uploadProgress: 0 });
                      processImage({
                        originalImageUrl: image.url,
                        editedImage,
                        editedImageTransformData,
                      });
                    }}
                    onAltChange={onAltUpdateCallback}
                    onDelete={onImageRemove}
                  />
                </div>
              )}
            </div>
            {(fieldDefinition.width || fieldDefinition.height || fieldDefinition.help) && (
              <span className="help-block">
                {fieldDefinition.help && (
                  <span>
                    {fieldDefinition.help}
                    <br />
                  </span>
                )}
                {(fieldDefinition.width || fieldDefinition.height) && (
                  <span>
                    <span>Dimensions: at least </span>
                    {fieldDefinition.width && <span> {fieldDefinition.width}px wide</span>}
                    {fieldDefinition.width && fieldDefinition.height && <span> and</span>}
                    {fieldDefinition.height && <span> {fieldDefinition.height}px tall</span>}.
                  </span>
                )}
              </span>
            )}
          </FileDragDrop>
        </FormGroup>
      );
  }
}

ImageFieldType.propTypes = propTypes;
ImageFieldType.defaultProps = defaultProps;

export default ImageFieldType;
