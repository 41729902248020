import { gql, useQuery } from '@apollo/client';

const TRANSFORM_URL_TO_IMAGE_QUERY = gql`
  query TransformUrltoImage($url: String!) {
    transformUrltoImage(url: $url) {
      id
      derived
      source_id
    }
  }
`;

export default function useTransformUrlToImage() {
  const { refetch, loading } = useQuery(TRANSFORM_URL_TO_IMAGE_QUERY, { skip: true });

  function transformUrlToImage(url) {
    return refetch({ url }).then((response) => response.data?.transformUrltoImage);
  }

  return { transformUrlToImage, loading };
}
